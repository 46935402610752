import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { submitFormData } from '../services/api.js';
import "../css/CustomerForm.css";

import FormHeader from '../sections/FormHeader.js';
import BasicInformation from '../sections/BasicInformation.js';
import HouseholdIncome from '../sections/HouseholdIncome.js';
import HouseholdExpenses from '../sections/HouseholdExpenses.js';
import SavingsInvestments from '../sections/SavingsInvestments.js';
import FinancialGoals from '../sections/FinancialGoals.js';
import RetirementPlanning from '../sections/RetirementPlanning.js';
import FixedAndLiquidAssets from '../sections/FixedAndLiquidAssets.js';
import Liabilities from '../sections/Liabilities.js';
import RiskProfile from '../sections/RiskProfile.js';
import InsuranceCoverage from '../sections/InsuranceCoverage.js';
import CreditBehavior from '../sections/CreditBehavior.js';
import ElderlyDependents from '../sections/ElderlyDependents.js';
import FamilyInvolvement from '../sections/FamilyInvolvement.js';
import FeedbackSuggestions from '../sections/FeedbackSuggestions.js';
import FormTranslations from '../sections/FormTranslations.js';
import Modal from '../components/Modal';


// Helper function to safely stringify objects
const safeStringify = (obj) => {
  const seen = new WeakSet();
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) return;
      seen.add(value);
    }
    return value;
  });
};

function CustomerForm() {
  const { token } = useParams();
  const [formData, setFormData] = useState({
    form_timestamp: new Date().toLocaleString(),
    name: '',
    email: '',
    mobile: '',
    dob: '',
    current_age: '',
    gender: '',
    city_of_residence: '',
    number_of_dependents: '',
    sole_earning_member: '',
    household_income: '',
    income_sources: '',
    income_level: '',
    monthly_expenses: '',
    mandatory_expenses: '',
    discretionary_expenses: '',
    emergency_fund: '',
    emergency_fund_coverage: '',
    rent_response: '',
    education_expenses: '',
    car_loan_emi: '',
    savings_investments: '',
    equity_investment: '',
    fixed_income_allocation: '',
    num_policies: '',
    premium_range: '',
    ppf_contribution: '',
    nps_contribution: '',
    financial_goals: '',
    home_accumulation: '',
    education_savings: '',
    wedding_savings: '',
    car_savings_response: '',
    world_tour_response: '',
    business_savings_response: '',
    wealth_goal_response: '',
    num_goals: '',
    retirement_age: '',
    estimated_corpus: '',
    estimated_retirement_need: '',
    desired_retirement_age: '',
    estimated_monthly_expenses: '',
    fixed_assets_value: '',
    liquid_assets_value: '',
    home_loan_outstanding: '',
    car_loan_outstanding: '',
    long_term_liabilities: '',
    long_term_management: '',
    new_liabilities: '',
    additional_payments: '',
    home_loan_tenure: '',
    car_loan_tenure: '',
    other_long_term_liabilities: '',
    other_loan_tenure: '',
    personal_loans_response: '',
    comfort_level_response: '',
    short_term_liabilities_response: '',
    health_insurance: '',
    term_insurance: '',
    disability_insurance: '',
    portfolio_reaction: '',
    risk_tolerance: '',
    holding_period: '',
    new_investment_products: '',
    investment_priority: '',
    high_risk_allocation: '',
    liquidity_importance: '',
    decision_maker: '',
    spouse_involvement: '',
    children_involvement: '',
    discussion_frequency: '',
    family_influence: '',
    expense_sharing: '',
    family_savings: '',
    credit_card_usage: '',
    credit_balance_management: '',
    credit_spending_approach: '',
    credit_card_attitude: '',
    credit_statement_review: '',
    spending_behavior: '',
    purchase_regret_frequency: '',
    budget_following: '',
    spending_prioritization: '',
    non_essential_decisions: '',
    budget_exceed_frequency: '',
    windfall_usage: '',
    online_shopping_frequency: '',
    income_increase_spending: '',
    elderly_dependents: '',
    ailments: '',
    independent_income: '',
    healthcare_plan: '',
    q81: '',
    q82: '',
    q83: '',
    q86: '',
    agent_id: ''
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [language, setLanguage] = useState('en');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateBasicInfoRef = useRef(() => true);
  const [showModal, setShowModal] = useState(false);

  const handleValidateBasicInfo = useCallback((validateFn) => {
    validateBasicInfoRef.current = validateFn;
  }, []);

  useEffect(() => {
    console.log("CustomerForm Token: ", token);
    const now = new Date();
    const formattedTimestamp = now.toISOString().slice(0, 19).replace('T', ' ');
    setFormData((prev) => ({ ...prev, form_timestamp: formattedTimestamp }));
    updateSectionIndicator();
  }, [token]);

  // Updated handleInputChange: if the event name is "updatedFormData", replace the entire state.
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      if (name === "updatedFormData") {
        return value;
      }
      const updated = { ...prev, [name]: value };
      if (name === 'form_language') {
        setLanguage(value);
        updateSectionIndicator();
      }
      if (name === 'dob') {
        if (value) {
          const dobDate = new Date(value);
          const today = new Date();
          let age = today.getFullYear() - dobDate.getFullYear();
          const monthDiff = today.getMonth() - dobDate.getMonth();
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
          }
          updated.current_age = age.toString();
        } else {
          updated.current_age = '';
        }
      }
      return updated;
    });
  };

  // Updated to use functional state update
  const handleFinancialGoalsChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => {
      let currentGoals = prev.financial_goals ? prev.financial_goals.split(',') : [];
      currentGoals = checked 
        ? [...currentGoals, value]
        : currentGoals.filter((goal) => goal !== value);
      return { ...prev, financial_goals: currentGoals.join(',') };
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => {
      let updatedIncomeSources = prev.income_sources ? prev.income_sources.split(',') : [];
      updatedIncomeSources = checked
        ? [...updatedIncomeSources, value]
        : updatedIncomeSources.filter((item) => item !== value);
      return { ...prev, income_sources: updatedIncomeSources.join(',') };
    });
  };

  const renderSection = () => {
    console.log("Rendering section, language:", language);
    const sectionProps = {
      formData,
      handleInputChange,
      language,
      validateSection: handleValidateBasicInfo,
    };
    const feedbackProps = {
      formData,
      handleInputChange,
      language,
      setFormData,
    };

    switch (step) {
      case 1:
        return <BasicInformation {...sectionProps} />;
      case 2:
        return <HouseholdIncome {...sectionProps} handleCheckboxChange={handleCheckboxChange} />;
      case 3:
        return <HouseholdExpenses {...sectionProps} key={JSON.stringify(formData)} />;
      case 4:
        return <SavingsInvestments {...sectionProps} />;
      case 5:
        return <FinancialGoals {...sectionProps} handleFinancialGoalsChange={handleFinancialGoalsChange} />;
      case 6:
        return <RetirementPlanning {...sectionProps} />;
      case 7:
        return <FixedAndLiquidAssets {...sectionProps} />;
      case 8:
        return <Liabilities {...sectionProps} />;
      case 9:
        return <InsuranceCoverage {...sectionProps} />;
      case 10:
        return <RiskProfile {...sectionProps} />;
      case 11:
        return <FamilyInvolvement {...sectionProps} />;
      case 12:
        return <CreditBehavior {...sectionProps} />;
      case 13:
        return <ElderlyDependents {...sectionProps} />;
      case 14:
        return <FeedbackSuggestions {...feedbackProps} />;
      default:
        return null;
    }
  };

  const updateSectionIndicator = () => {
    const sectionIndicator = document.getElementById('sectionIndicator');
    if (sectionIndicator) {
      const englishText = `Section ${step} of 14 - ${getSectionTitle()}`;
      const hindiText = `अनुभाग ${step} में से 14 - ${getSectionTitleHindi()}`;
      sectionIndicator.textContent = language === 'en' ? englishText : hindiText;
    }
  };

  const getSectionTitle = () => {
    const titles = [
      "Basic Information",
      "Household Income",
      "Household Expenses",
      "Savings and Investments",
      "Financial Goals",
      "Retirement Planning",
      "Fixed & Liquid Assets",
      "Liabilities (Loans, Credit, Debt)",
      "Insurance Coverage",
      "Risk Profile",
      "Family's Involvement in Financial Planning",
      "Credit Behavior & Spending Habits",
      "Elderly Dependents",
      "Feedback & Suggestions",
    ];
    return titles[step - 1] || "";
  };

  const getSectionTitleHindi = () => {
    const titles = [
      "मूल जानकारी",
      "घरेलू आय",
      "घरेलू खर्च",
      "बचत और निवेश",
      "आर्थिक लक्ष्य",
      "सेवानिवृत्ति योजना",
      "स्थायी एवं तरल संपत्तियाँ",
      "देनदारियाँ (लोन, क्रेडिट, ऋण)",
      "बीमा कवरेज",
      "जोखिम प्रोफ़ाइल",
      "परिवार की वित्तीय योजना में भागीदारी",
      "क्रेडिट व्यवहार और खर्च की आदतें",
      "बुजुर्ग निर्भरता",
      "प्रतिक्रिया और सुझाव",
    ];
    return titles[step - 1] || "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateBasicInfoRef.current()) {
      setError('Please complete all required fields');
      return;
    }

    setIsSubmitting(true);
    setError(null);
    try {
      const clonedData =
        typeof structuredClone === 'function'
          ? structuredClone(formData)
          : JSON.parse(safeStringify(formData));

      const formattedData = {
        ...clonedData,
        dob: formData.dob ? new Date(formData.dob).toISOString().split("T")[0] : '',
        token,
      };

      await submitFormData(formattedData);
      setSubmissionStatus('success');
      setShowModal(true); // Show the modal

     /* setFormData(prev => ({
        ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
        form_timestamp: ''
      })); */

    } catch (err) {
      setSubmissionStatus('error');
      setError(err.response?.data || 'Form submission failed. Please try again.');
      console.error('Form submission error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = () => {
    if (submissionStatus === 'success') return; // 🔒 Prevent navigating after submission

    if (validateBasicInfoRef.current()) {
      setStep(prev => prev + 1);
      updateSectionIndicator();
    } else {
      console.log("Validation failed for section", step);
    }
  };

  const handlePrevious = () => {
    if (submissionStatus === 'success') return; // 🔒 Prevent navigating after submission

    setStep(prev => prev - 1);
    updateSectionIndicator();
  };

  const buttonTranslations = FormTranslations[language].buttons;

  return (
    <div className="customer-form-container">
      <div className="customer-form">
        <FormHeader
          step={step}
          getSectionTitle={getSectionTitle}
          getSectionTitleHindi={getSectionTitleHindi}
          language={language}
          formTranslations={FormTranslations}
        />
        <form onSubmit={handleSubmit}>
          {renderSection()}
          <div className="navigation-buttons">
          {step > 1 && (
          <button
            type="button"
            onClick={handlePrevious}
            disabled={isSubmitting || submissionStatus === 'success'}
          >
            {buttonTranslations.prev}
          </button>
             )}

            {step < 14 ? (
              <button
                type="button"
                onClick={handleNext}
                disabled={isSubmitting}
              >
                {buttonTranslations.next}
              </button>
            ) : (
              <button type="submit" disabled={isSubmitting || submissionStatus === 'success'}>
              {isSubmitting ? 'Submitting...' : buttonTranslations.submit}
            </button>
            
            )}
           

          </div>
          <Modal show={showModal} onClose={() => setShowModal(false)}>
              <h2>🎉 Form Submitted Successfully!</h2>
              <p>Thank you for your response.</p>
            </Modal>
          {submissionStatus === 'error' && (
            <p className="error-message">{error}</p>
          )}
        </form>
      </div>
    </div>
  );
}

export default CustomerForm;
