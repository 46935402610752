// components/Modal.js
import React from 'react';
import "../css/Modal.css";

const Modal = ({ show, onClose, children }) => {
    console.log("Modal should render:", show);

  if (!show) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-box">
        {children}
        <button className="close-btn" onClick={onClose}>Close</button>
      </div>
    </div>

  );
};

export default Modal;
